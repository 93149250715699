import React from "react";
import Logo2 from "../../assets/Logo/logo.png";
import { Link } from "react-router-dom";
import {
  faPhone,
  faMapMarkerAlt,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faLinkedin,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const navigationLinks = [
    { path: "/", label: "Početna" },
    { path: "/o-nama", label: "O nama" },
    { path: "/usluge", label: "Usluge" },
    { path: "/galerija", label: "Galerija" },
    { path: "/success-stories", label: "Uspješne priče" },
    { path: "/kontakt", label: "Kontakt" },
  ];

  const additionalLinks = [
    { path: "/usluge", text: "Usluge" },
    { path: "/smjestaj", text: "Smještaj" },
    { path: "/opstaNjega", text: "Opšta i medicinska njega" },
    { path: "/rehabilitacija", text: "Rehabilitacija" },
    { path: "/dom", text: "Dnevni boravak" },
    { path: "https://www.mozdaniudar.ba/", text: "Moždani udar" },
  ];

  const socialLinks = [
    {
      url: "https://www.instagram.com/ustanova_familia/",
      icon: faInstagram,
      label: "Instagram",
      className: "hover:text-red-500",
    },
    {
      url: "https://www.facebook.com/domfamilia1/",
      icon: faFacebook,
      label: "Facebook",
      className: "hover:text-blue-700",
    },
    {
      url: "https://www.linkedin.com/company/domfamilia/",
      icon: faLinkedin,
      label: "LinkedIn",
      className: "hover:text-blue-400",
    },
    {
      url: "https://www.youtube.com/@ustanovafamilia4984",
      icon: faYoutube,
      label: "Youtube",
      className: "hover:text-red-600",
    },
  ];

  return (
    <footer className="bg-TamnoPlava text-white font-poppins p-4 relative w-full">
      <div className="max-w-screen-xl mx-auto grid gap-6 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 py-8">
        {/* Logo and Contact Information */}
        <div className="text-center">
          <div className="flex flex-col items-center">
            <img src={Logo2} alt="Familia Logo" className="w-20 h-auto mb-3" />
            <span className="text-3xl font-light mb-3 text-white">Familia</span>
            <p className="hover:underline">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
              <a
                href="https://www.google.com/maps/search/?api=1&query=Briješće+Polje+4,+71000+Sarajevo,+BiH"
                target="_blank"
                rel="noopener noreferrer"
              >
                Briješće Polje 4, 71000 Sarajevo, BiH
              </a>
            </p>
            <p className="hover:underline mb-1">
              <FontAwesomeIcon icon={faMailBulk} className="mr-2" />
              <a href="mailto:info@familia.ba">info@familia.ba</a>
            </p>
            <p className="hover:underline mb-1">
              <FontAwesomeIcon icon={faPhone} className="mr-2" />
              <a href="tel:+38733956480">+387 33 956 480</a>
            </p>
          </div>
        </div>

        {/* Navigation Links */}
        <div className="flex flex-col mx-2">
          <h2 className="text-2xl font-normal font-poppins mb-4">Ustanova</h2>
          <ul className="space-y-2">
            {navigationLinks.map((link, index) => (
              <li
                key={index}
                className="text-gray-400 cursor-pointer hover:underline"
              >
                <Link to={link.path}>{link.label}</Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Additional Links */}
        <div className="flex flex-col mx-2">
          <h2 className="text-2xl font-normal font-poppins mb-4">Usluge</h2>
          <ul className="space-y-2">
            {additionalLinks.map((link, index) => (
              <li
                key={index}
                className="text-gray-400 cursor-pointer hover:underline"
              >
                {link.path.startsWith("http") ? (
                  <a href={link.path} target="_blank" rel="noopener noreferrer">
                    {link.text}
                  </a>
                ) : (
                  <Link to={link.path}>{link.text}</Link>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Newsletter Form and Social Links */}
        <div className="flex flex-col items-center">
          <form
            className="text-black w-full"
            action="https://familia.us22.list-manage.com/subscribe/post?u=6ef241caa0a7acee875a9ecba&amp;id=1277e026ee&amp;f_id=0089cee1f0"
            method="post"
            target="_blank"
            noValidate
          >
            <h2 className="text-lg font-normal font-poppins text-white mb-2">
              Pretplatite se za novosti
            </h2>
            <input
              type="email"
              name="EMAIL"
              placeholder="Subscribe to our email"
              className="w-full border-none rounded-lg py-3 px-4 mb-4 mt-5"
            />
            <div className="absolute left-[-5000px]" aria-hidden="true">
              <input
                type="text"
                name="b_YOUR_U_YOUR_ID"
                tabIndex="-1"
                placeholder="Search..."
              />
            </div>
            <button
              type="submit"
              className="w-full py-3 bg-blue-600 text-white rounded-lg"
            >
              Subscribe
            </button>
          </form>
          <div className="mt-6 flex space-x-8 ">
            {socialLinks.map((link) => (
              <a
                key={link.url}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className={`text-white ${link.className}`}
              >
                <FontAwesomeIcon icon={link.icon} size="3x" />
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="text-center text-gray-400 py-4">
      <span>Neovlaštena zluopotreba fotografija će snositi posljedice. </span>
        <span>Copyright © 2024 Familia ltd.</span>
        <span> All rights reserved</span>
      </div>
    </footer>
  );
};

export default Footer;
