import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga4";
import router from "./utils/router";
import { Header } from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./utils/ScrollToTop";
import { useEffect } from "react";

const TRACKING_ID = "G-GNZ3179R4P";
ReactGA.initialize(TRACKING_ID);

const GAListener = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      title: location.pathname,
    });
  }, [location]);

  return null;
};


const App = () => {
  return (
    <div className="min-h-[100vh] flex flex-col">
      <Router>
        <GAListener />
        <Header />
        <ScrollToTop>
          <Routes>
            {router.map((page) => (
              <Route key={page.path} path={page.path} element={page.element} />
            ))}
          </Routes>
        </ScrollToTop>
        <div className="mt-auto">
          <Footer />
        </div>
      </Router>
    </div>
  );
};

export default App;
