import React from "react";
import BiodexSistem from "../../assets/images/Fizikalna/BiodexSsistem.JPG";
import AmadeoRuke from "../../assets/images/Intezivna/Amadeo 2.jpg";
import RastereceniHod from "../../assets/images/Intezivna/hod.jpg";
import BiodexPloca from "../../assets/images/Fizikalna/biodexPloca.jpg";
import { Helmet } from "react-helmet";

export const RobotskaRehab = () => {
  return (
    <div className="flex flex-col">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Robotska rehabilitacija</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
      <div className="flex flex-col md:flex-row py-4 mt-[96px]">
        <div className="md:w-[40%]">
          <img
            src={BiodexSistem}
            alt="Robotska Rehabilitacija"
            className="w-full h-[500px] lg:h-[600px] rounded object-cover"
          />
        </div>
        <div className="md:w-[60%] md:px-6 mt-2 md:mt-0">
          <h1 className="text-3xl break-words xl:text-5xl 2xl:text-5xl align-top text-TamnoPlava mb-5 mt-5  font-extrabold">
            Robotski izokinetički dinamometar Biodex sistem 4
          </h1>
          <p className="mb-4 text-2xl">
            Koristi se u rehabilitaciji nakon cerebrovaskularnih incidenata
            (CVI) za procjenu i poboljšanje funkcionalnih sposobnosti
            pacijenata. Omogućuje nam:
          </p>
          <ul className="list-disc custom-bullets list-inside p-5 text-lg">
            <li>
              Procjenu snage i ravnoteže: Mjeri mišićnu snagu, stabilnost i
              koordinaciju.
            </li>
            <li>
              Rehabilitacijske vježbe: Pruža prilagođene vježbe za poboljšanje
              funkcionalnih sposobnosti.
            </li>
            <li>
              Praćenje napretka: Omogućava praćenje napretka i prilagodbu
              terapijskog plana.
            </li>
          </ul>
          <p className="mt-4 text-lg">
            Korištenjem ovog sistema, terapeut može precizno planirati i
            prilagoditi rehabilitaciju kako bi se maksimizirao oporavak
            pacijenta.
          </p>
        </div>
      </div>
      {/*Robotizirani aparati za vjezbanjeruku*/}
      <div className="flex flex-col md:flex-row ">
        <div className="md:w-[40%]">
          <img
            src={AmadeoRuke}
            alt="Robotska Rehabilitacija"
            className="w-full h-[500px] lg:h-[800px] rounded object-cover"
          />
        </div>
        <div className="md:w-[60%] md:px-6 mt-2 md:mt-0">
          <h1 className="text-3xl break-words xl:text-5xl 2xl:text-5xl align-top text-TamnoPlava mb-5 mt-5  font-extrabold">
            Robotizirani aparati za vježbanje ruku
          </h1>
          <p className="mb-4 text-2xl">
            Amadeo robot je važan u rehabilitaciji šake nakon cerebrovaskularnog
            incidenta (CVI) zbog svoje sposobnosti da:
          </p>
          <ul className="list-disc custom-bullets list-inside px-5 text-lg">
            <li>
              Pruža ciljane vježbe: Omogućava precizne i ponovljene pokrete
              šake.
            </li>
            <li>
              Potpomaže aktivnu rehabilitaciju: Aktivira i stimulira mišiće i
              živce, poboljšavajući funkcionalnost.
            </li>
            <li>
              Omogućava prilagodbu: Postavlja individualizirane vježbe prema
              potrebama pacijenta.
            </li>
          </ul>
          <p className="mt-4 text-lg">
            Ovaj robot pomaže u obnavljanju finih motoričkih sposobnosti i
            poboljšava svakodnevne funkcije ruke.
          </p>
          <h2 className="mt-2 font-poppins text-2xl">
            Robot Ratlus koristi se u rehabilitaciji nakon cerebrovaskularnog
            incidenta (CVI) za obnovu funkcije zgloba šake, lakta i ramena. Evo
            kako funkcioniše:
          </h2>
          <ul className="list-disc custom-bullets list-inside px-5 text-lg">
            <li>
              Ciljane vježbe: Ratlus robot pruža ciljanje vježbe za zglob šake,
              lakta i ramena kroz vođene pokrete, što pomaže u obnovi
              pokretljivosti i snage.
            </li>
            <li>
              Precizna rehabilitacija: Koristi se za izvođenje specifičnih
              vježbi koje poboljšavaju raspon pokreta i funkcionalnost zapešća,
              lakta i ramena.
            </li>
            <li>
              Personalizirani protokoli: Omogućavaju prilagodbu
              rehabilitacijskog plana prema individualnim potrebama pacijenta,
              osiguravajući optimalan oporavak.
            </li>
            <li>
              Praćenje napretka: Sistem bilježi napredak i prilagođava vježbe na
              temelju performansi, što pomaže terapeutima u prilagodbi terapije
              i postizanju boljih rezultata.
            </li>
            <li>
              Povećanje funkcionalnosti: Pomaže pacijentima u povratku
              svakodnevnim aktivnostima kroz poboljšanje funkcionalnosti gornjih
              ekstremiteta.
            </li>
          </ul>
          <p className="text-base mt-2">
            Robot Ratlus pomaže u efikasnijem i preciznijem oporavku
            funkcionalnosti gornjih ekstremiteta nakon CVI, pružajući terapijski
            alat za postizanje boljih rezultata rehabilitacije.
          </p>
        </div>
      </div>
      {/*Biodex Sistem za rasterećeni hod*/}
      <div className="flex flex-col md:flex-row py-4">
        <div className="md:w-[40%]">
          <img
            src={RastereceniHod}
            alt="Biodex Sistem za rasterećeni hod"
            loading="lazy"
            className="w-full h-[500px] lg:h-[600px] rounded object-cover"
          />
        </div>
        <div className="md:w-[60%] md:px-6 mt-2 md:mt-0">
          <h1 className="text-3xl break-words xl:text-5xl 2xl:text-5xl align-top text-TamnoPlava mb-5 mt-5  font-extrabold">
            Biodex Sistem za rasterećeni hod
          </h1>
          <p className="mb-4 text-2xl">
            koristi se u rehabilitaciji nakon moždanog udara za poboljšanje
            hodanja i ravnoteže. Ovaj sistem omogućava:
          </p>
          <ul className="list-disc custom-bullets list-inside p-5 text-lg">
            <li>
              Simulaciju hodanja sa podrškom: Pacijent hoda na traci koja pruža
              kontroliranu potporu i smanjuje opterećenje na nogama. Ovo
              omogućava pacijentima da vježbaju hodanje u sigurnom okruženju,
              bez prekomjernog opterećenja.
            </li>
            <li>
              Kontrolu opterećenja: Pomaže u postepenom smanjenju potpore kako
              bi se pacijentima omogućilo da postupno preuzmu više težine na
              nogama, čime se poboljšava njihova sposobnost hodanja i ravnoteže.
            </li>
            <li>
              Praćenje napretka: Sistem bilježi podatke o hodanju, uključujući
              brzinu, trajanje i kvalitetu pokreta, što omogućava terapeutima da
              prate napredak i prilagode rehabilitacijski plan.
            </li>
          </ul>
          <p className="mt-4 text-lg">
            Ovaj pristup pomaže u obnavljanju funkcionalnosti hodanja, povećava
            samopouzdanje pacijenata i smanjuje rizik od pada.
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row py-4">
        <div className="md:w-[40%]">
          <img
            src={BiodexPloca}
            alt="Biodex balans ploča"
            loading="lazy"
            className="w-full h-[500px] lg:h-[600px] rounded object-cover"
          />
        </div>
        <div className="md:w-[60%] md:px-6 mt-2 md:mt-0">
          <h1 className="text-3xl break-words xl:text-5xl 2xl:text-5xl align-top text-TamnoPlava mb-5 mt-5  font-extrabold">
            Biodex balans ploča
          </h1>
          <p className="mb-4 text-2xl">
            koristi se u rehabilitaciji nakon cerebrovaskularnog incidenta (CVI)
            kako bi poboljšala ravnotežu i koordinaciju. Evo ključnih aspekata
            njezine važnosti:
          </p>
          <ul className="list-disc custom-bullets list-inside p-5 text-lg">
            <li>
              Procjena ravnoteže: Omogućava precizno mjerenje stabilnosti i
              sposobnosti pacijenta da održava ravnotežu u različitim uvjetima.
            </li>
            <li>
              Poboljšanje ravnoteže i koordinacije: Kroz vježbe koje uključuju
              nestabilne površine, pomaže pacijentima da razviju bolje
              sposobnosti ravnoteže i koordinacije.
            </li>
            <li>
              Povećanje funkcionalne sposobnosti: Pomaže u obnavljanju
              sposobnosti za obavljanje svakodnevnih aktivnosti, smanjujući
              rizik od pada i poboljšavajući opću mobilnost.
            </li>
            <li>
              Praćenje napretka: Sistem bilježi napredak u ravnoteži,
              omogućavajući terapeutima da prilagode rehabilitacijske programe
              prema potrebama pacijenta.
            </li>
          </ul>
          <p className="mt-4 text-lg">
            Korištenje Biodex balans ploče može značajno poboljšati funkcionalne
            rezultate rehabilitacije nakon CVI, pomažući pacijentima da se bolje
            oporave i vrate normalnim aktivnostima.
          </p>
        </div>
      </div>
    </div>
  );
};
